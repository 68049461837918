// import { BayanNgGubat } from "./SorsogonMunicipalities/BayanNgGubat"
// import {Matnog} from "./SorsogonMunicipalities/Matnog"

export const CamarinessurDetails = [
  {
    id: 1,
    title: "Baao",
    alt: "Baao",
    url: "/baao",
    municipalityList: [],
  },
  {
    id: 2,
    title: "Balatan",
    alt: "Balatan",
    url: "/balatan",
    municipalityList: [],
  },
  {
    id: 3,
    title: "Bato",
    alt: "Bato",
    url: "/bato",
    municipalityList: [],
  },
  {
    id: 4,
    title: "Bombon",
    alt: "Bombon",
    url: "/bombon",
    municipalityList: [],
  },
  {
    id: 5,
    title: "Buhi",
    alt: "Buhi",
    url: "/buhi",
    municipalityList: [],
  },
  {
    id: 6,
    title: "Bula",
    alt: "Bula",
    url: "/bula",
    municipalityList: [],
  },
  {
    id: 7,
    title: "Cabusao",
    alt: "Cabusao",
    url: "/cabusao",
    municipalityList: [],
  },
  {
    id: 8,
    title: "Calabanga",
    alt: "Calabanga",
    url: "/calabanga",
    municipalityList: [],
  },
  {
    id: 9,
    title: "Camaligan",
    alt: "Camaligan",
    url: "/camaligan",
    municipalityList: [],
  },
  {
    id: 10,
    title: "Canaman",
    alt: "Canaman",
    url: "/canaman",
    municipalityList: [],
  },
  {
    id: 11,
    title: "Caramoan",
    alt: "Caramoan",
    url: "/caramoan",
    municipalityList: [],
  },
  {
    id: 12,
    title: "Del Gallego",
    alt: "Del Gallego",
    url: "/delgallego",
    municipalityList: [],
  },
  {
    id: 13,
    title: "Gainza",
    alt: "Gainza",
    url: "/gainza",
    municipalityList: [],
  },
  {
    id: 14,
    title: "Garchitorena",
    alt: "Garchitorena",
    url: "/garchitorena",
    municipalityList: [],
  },
  {
    id: 15,
    title: "Goa",
    alt: "Goa",
    url: "/goa",
    municipalityList: [],
  },
  {
    id: 16,
    title: "Iriga",
    alt: "Iriga",
    url: "/iriga",
    municipalityList: [],
  },
  {
    id: 17,
    title: "Lagonoy",
    alt: "Lagonoy",
    url: "/lagonoy",
    municipalityList: [],
  },
  {
    id: 18,
    title: "Libmanan",
    alt: "Libmanan",
    url: "/libmanan",
    municipalityList: [],
  },
  {
    id: 19,
    title: "Lupi",
    alt: "Lupi",
    url: "/lupi",
    municipalityList: [],
  },
  {
    id: 20,
    title: "Magarao",
    alt: "Magarao",
    url: "/magarao",
    municipalityList: [],
  },
  {
    id: 21,
    title: "Milaor",
    alt: "Milaor",
    url: "/milaor",
    municipalityList: [],
  },
  {
    id: 22,
    title: "Minalabac",
    alt: "Minalabac",
    url: "/minalabac",
    municipalityList: [],
  },
  {
    id: 23,
    title: "Nabua",
    alt: "Nabua",
    url: "/nabua",
    municipalityList: [],
  },
  {
    id: 24,
    title: "Naga",
    alt: "Naga",
    url: "/naga",
    municipalityList: [],
  },
  {
    id: 25,
    title: "Ocampo",
    alt: "Ocampo",
    url: "/ocampo",
    municipalityList: [],
  },
  {
    id: 26,
    title: "Pamplona",
    alt: "Pamplona",
    url: "/pamplona",
    municipalityList: [],
  },
  {
    id: 27,
    title: "Pasacao",
    alt: "Pasacao",
    url: "/pasacao",
    municipalityList: [],
  },
  {
    id: 28,
    title: "Pili",
    alt: "Pili",
    url: "/pili",
    municipalityList: [],
  },
  {
    id: 29,
    title: "Presentacion",
    alt: "Presentacion",
    url: "/precentacion",
    municipalityList: [],
  },
  {
    id: 30,
    title: "Ragay",
    alt: "Ragay",
    url: "/ragay",
    municipalityList: [],
  },
  {
    id: 31,
    title: "Sagñay",
    alt: "Sagñay",
    url: "/sagñay",
    municipalityList: [],
  },
  {
    id: 32,
    title: "San Fernando",
    alt: "San Fernando",
    url: "/sanfernando",
    municipalityList: [],
  },
  {
    id: 33,
    title: "San Jose",
    alt: "San Jose",
    url: "/sanjose",
    municipalityList: [],
  },
  {
    id: 34,
    title: "Sipocot",
    alt: "Sipocot",
    url: "/sipocot",
    municipalityList: [],
  },
  {
    id: 35,
    title: "Siruma",
    alt: "Siruma",
    url: "/siruma",
    municipalityList: [],
  },
  {
    id: 36,
    title: "Tigaon",
    alt: "Tigaon",
    url: "/tigaon",
    municipalityList: [],
  },
  {
    id: 37,
    title: "Tinambac",
    alt: "Tinambac",
    url: "/tinambac",
    municipalityList: [],
  },
];
