// import { BayanNgGubat } from "./SorsogonMunicipalities/BayanNgGubat"
// import {Matnog} from "./SorsogonMunicipalities/Matnog"

export const AlbayDetails = [
  {
    id: 1,
    title: "Bacacay",
    alt: "Bacacay",
    url: "/bacacay",
    municipalityList: [],
  },
  {
    id: 2,
    title: "Camalig",
    alt: "Camalig",
    url: "/camalig",
    municipalityList: [],
  },
  {
    id: 3,
    title: "Daraga",
    alt: "Daraga",
    url: "/daraga",
    municipalityList: [],
  },
  {
    id: 4,
    title: "Guinobatan",
    alt: "Guinobatan",
    url: "/guinobatan",
    municipalityList: [],
  },
  {
    id: 5,
    title: "Jovellar",
    alt: "Jovellar",
    url: "/jovellar",
    municipalityList: [],
  },
  {
    id: 6,
    title: "Legazpi",
    alt: "Legazpi",
    url: "/legazpi",
    municipalityList: [],
  },
  {
    id: 7,
    title: "Libon",
    alt: "Libon",
    url: "/libon",
    municipalityList: [],
  },
  {
    id: 8,
    title: "Ligao",
    alt: "Ligao",
    url: "/ligao",
    municipalityList: [],
  },
  {
    id: 9,
    title: "Malilipot",
    alt: "Malilipot",
    url: "/malilipot",
    municipalityList: [],
  },
  {
    id: 10,
    title: "Malinao",
    alt: "Malinao",
    url: "/malinao",
    municipalityList: [],
  },
  {
    id: 11,
    title: "Manito",
    alt: "Manito",
    url: "/manito",
    municipalityList: [],
  },
  {
    id: 12,
    title: "Oas",
    alt: "Oas",
    url: "/oas",
    municipalityList: [],
  },
  {
    id: 13,
    title: "Pio Duran",
    alt: "Pio Duran",
    url: "/pioduran",
    municipalityList: [],
  },
  {
    id: 14,
    title: "Polangui",
    alt: "Polangui",
    url: "/polangui",
    municipalityList: [],
  },
  {
    id: 15,
    title: "Rapu-Rapu",
    alt: "Rapu-Rapu",
    url: "/rapu-rapu",
    municipalityList: [],
  },
  {
    id: 16,
    title: "Santo Domingo",
    alt: "Santo Domingo",
    url: "/santodomingo",
    municipalityList: [],
  },
  {
    id: 17,
    title: "Tabaco",
    alt: "Tabaco",
    url: "/tabaco",
    municipalityList: [],
  },
  {
    id: 18,
    title: "Tiwi",
    alt: "Tiwi",
    url: "/tiwi",
    municipalityList: [],
  },
];
